import {getAndSetCityList} from 'Src/actions/RegistrationActions'
import {createNewLead, getDrLeadFromResponse} from 'Src/apis/leads'
import {buildCustomerDataFromResponse} from 'Src/reducers/CustomerReducer'
import {formatToInternationalNumber} from 'Src/utilities/formatters'
import {stateFromZip} from 'Src/utilities/Helpers'

const buildMeta = (customer, meta) => {
  const body = {meta}

  if (customer.id) {
    body.meta.type = 'customer'
    body.meta.key = customer.id
  } else if (meta.method === 'email') {
    body.meta.type = 'email'
    body.meta.key = customer.email
  } else {
    body.meta.type = 'phone'
    body.meta.key = formatToInternationalNumber(customer.phone)
  }

  return body
}

const addCustomerData = (body, customer) => {
  body.data = {
    type: 'customers',
    attributes: {
      first_name: customer.firstName,
      last_name: customer.lastName,
      email: customer.email,
    },
    relationships: {
      addresses: {
        data: [{type: 'addresses'}],
      },
    },
  }
  body.included = [
    {
      type: 'addresses',
      attributes: {
        state_id: stateFromZip(customer.zipCode)?.stateId,
        zip_code: customer.zipCode,
        city: customer.cityCounty?.city,
        county: customer.cityCounty?.county,
        region_id: customer.cityCounty?.id,
        in_city: customer.cityCounty?.inCity,
      },
    },
  ]

  return body
}

const addPhone = (body, phone, phoneTypeId) => {
  const isPrimary = 1
  const formattedPhone = formatToInternationalNumber(phone)
  body.data.attributes.phone = formattedPhone
  body.data.relationships = {
    ...body.data.relationships,
    phones: {
      data: [{type: 'phones'}],
    },
  }
  body.included = [
    ...body.included,
    {
      type: 'phones',
      attributes: {
        number: formattedPhone,
        is_primary: isPrimary,
      },
      relationships: {
        phoneType: {
          data: {
            type: 'phone_types',
            id: phoneTypeId,
          },
        },
      },
    },
  ]

  return body
}

export const sendVerification = async (a2zApi, customer, meta) =>
  a2zApi.post(`/send-verification`, buildMeta(customer, meta))

export const checkVerification = async (a2zApi, customer, phoneTypeId, meta) => {
  let body = buildMeta(customer, meta)

  // If we don't have a Customer ID, we'll be creating a new Customer
  if (customer && !customer.id) {
    body = addCustomerData(body, customer)

    // If the Customer gave us their phone, we add that relationship
    if (customer.phone) {
      body = addPhone(body, customer.phone, phoneTypeId)
    }
  }

  return a2zApi.post(`/check-verification?include=leads,addresses,phones`, body)
}

export const whoami = async (
  a2zApi,
  a2zCustomerApi,
  customerData,
  customerDispatch,
  registrationDispatch,
  history
) => {
  try {
    const {data: responseData} = await a2zCustomerApi.get('/whoami?include=leads,addresses')

    customerData = buildCustomerDataFromResponse(responseData, customerData)

    customerDispatch({type: 'setCustomerData', payload: customerData})

    const cityList = await getAndSetCityList(a2zApi, customerData.zipCode, registrationDispatch)

    if (!customerData.cityCounty.id) {
      customerData.cityCounty.id = cityList[0].id
      customerData.cityCounty.city = cityList[0].attributes.city
      customerData.cityCounty.county = cityList[0].attributes.county
      customerData.cityCounty.inCity = cityList[0].attributes.inCity
    }

    customerDispatch({
      type: 'setCustomerCityCounty',
      payload: {cityCounty: customerData.cityCounty, cityList},
    })

    // If no DR leads were found for this returning Customer, let's create one now
    const lead =
      getDrLeadFromResponse(responseData) ||
      (await createNewLead(a2zCustomerApi, responseData.data.id))

    customerDispatch({type: 'setLeadId', payload: lead.id})

    return {customerData, lead}
  } catch {
    // whoami failed probably b/c the token was expired or invalid
    history.push('/register')
  }

  return {lead: null}
}

export const logout = async (a2zCustomerApi) => {
  return a2zCustomerApi.get('/logout').catch((error) => {
    console.error(error)
  })
}

export const verificationActionsTestFunctions = {
  buildMeta,
  addCustomerData,
  addPhone,
}
